<template>
  <div class="home">
    <NavigationBar/>
    <IntroductionComponent/>
    <ExperienceComponent/>
    <EducationComponent/>
    <LatestProjects/>
    <FooterComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import NavigationBar from '@/components/NavigationBar.vue';
import IntroductionComponent from '@/components/IntroductionComponent.vue';
import ExperienceComponent from '@/components/ExperienceComponent.vue';
import EducationComponent from '@/components/EducationComponent.vue';
import LatestProjects from '@/components/LatestProjects.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'HomeView',
  components: {
    NavigationBar,
    IntroductionComponent,
    ExperienceComponent,
    EducationComponent,
    LatestProjects,
    FooterComponent
  }
}
</script>

<style lang="css">

.home {
  background-color: #1D1D1D;
}

</style>
<template>
  <div>
    <nav>
      <NavigationBar/>
    </nav>
    <router-view/>
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue';
export default {
  name: "App",
  components: {
    NavigationBar
  }
};
</script>

<style lang="css">

nav {
  background-color: #1D1D1D;
}

</style>

<template>
    <div class="latestprojects container-fluid" id="projects">
        <div class="row">
            <div class="col-sm-12 col-md-3 flex-col nadpis">
                <h2 class="text-center">Latest Projects</h2>
            </div>
        </div>
        <div class="row projekty">
            <div class="col-sm-12 col-md-6 flex-col latestproject">
                <a href="https://nostalgie.sk/" target="_blank" class="live-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1M8 13h8v-2H8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5" />
                        </svg>
                </a>
                <a href="https://github.com/ZuzanaGabonayova/nostalgie" target="_blank" class="github-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <g fill="none">
                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                            <path fill="currentColor" d="M6.315 6.176c-.25-.638-.24-1.367-.129-2.034a6.77 6.77 0 0 1 2.12 1.07c.28.214.647.283.989.18A9.343 9.343 0 0 1 12 5c.961 0 1.874.14 2.703.391c.342.104.709.034.988-.18a6.77 6.77 0 0 1 2.119-1.07c.111.667.12 1.396-.128 2.033c-.15.384-.075.826.208 1.14C18.614 8.117 19 9.04 19 10c0 2.114-1.97 4.187-5.134 4.818c-.792.158-1.101 1.155-.495 1.726c.389.366.629.882.629 1.456v3a1 1 0 0 0 2 0v-3c0-.57-.12-1.112-.334-1.603C18.683 15.35 21 12.993 21 10c0-1.347-.484-2.585-1.287-3.622c.21-.82.191-1.646.111-2.28c-.071-.568-.17-1.312-.57-1.756c-.595-.659-1.58-.271-2.28-.032a9.081 9.081 0 0 0-2.125 1.045A11.432 11.432 0 0 0 12 3c-.994 0-1.953.125-2.851.356a9.08 9.08 0 0 0-2.125-1.045c-.7-.24-1.686-.628-2.281.031c-.408.452-.493 1.137-.566 1.719l-.005.038c-.08.635-.098 1.462.112 2.283C3.484 7.418 3 8.654 3 10c0 2.992 2.317 5.35 5.334 6.397A3.986 3.986 0 0 0 8 17.98l-.168.034c-.717.099-1.176.01-1.488-.122c-.76-.322-1.152-1.133-1.63-1.753c-.298-.385-.732-.866-1.398-1.088a1 1 0 0 0-.632 1.898c.558.186.944 1.142 1.298 1.566c.373.448.869.916 1.58 1.218c.682.29 1.483.393 2.438.276V21a1 1 0 0 0 2 0v-3c0-.574.24-1.09.629-1.456c.607-.572.297-1.568-.495-1.726C6.969 14.187 5 12.114 5 10c0-.958.385-1.881 1.108-2.684c.283-.314.357-.756.207-1.14" />
                        </g>
                    </svg>
                </a>
                <div class="project-wrapper">
                    <img src="../assets/nostalgie-mockup.png" alt="Nostalgie Project">
                    <div class="project-title">Nostalgie Restaurant</div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 flex-col latestproject">
                <a href="https://techhubsouth.web.app/" target="_blank" class="live-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1M8 13h8v-2H8zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5" />
                        </svg>
                </a>
                <a href="https://github.com/ZuzanaGabonayova/techhub-syd-project" target="_blank" class="github-link">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                        <g fill="none">
                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                            <path fill="currentColor" d="M6.315 6.176c-.25-.638-.24-1.367-.129-2.034a6.77 6.77 0 0 1 2.12 1.07c.28.214.647.283.989.18A9.343 9.343 0 0 1 12 5c.961 0 1.874.14 2.703.391c.342.104.709.034.988-.18a6.77 6.77 0 0 1 2.119-1.07c.111.667.12 1.396-.128 2.033c-.15.384-.075.826.208 1.14C18.614 8.117 19 9.04 19 10c0 2.114-1.97 4.187-5.134 4.818c-.792.158-1.101 1.155-.495 1.726c.389.366.629.882.629 1.456v3a1 1 0 0 0 2 0v-3c0-.57-.12-1.112-.334-1.603C18.683 15.35 21 12.993 21 10c0-1.347-.484-2.585-1.287-3.622c.21-.82.191-1.646.111-2.28c-.071-.568-.17-1.312-.57-1.756c-.595-.659-1.58-.271-2.28-.032a9.081 9.081 0 0 0-2.125 1.045A11.432 11.432 0 0 0 12 3c-.994 0-1.953.125-2.851.356a9.08 9.08 0 0 0-2.125-1.045c-.7-.24-1.686-.628-2.281.031c-.408.452-.493 1.137-.566 1.719l-.005.038c-.08.635-.098 1.462.112 2.283C3.484 7.418 3 8.654 3 10c0 2.992 2.317 5.35 5.334 6.397A3.986 3.986 0 0 0 8 17.98l-.168.034c-.717.099-1.176.01-1.488-.122c-.76-.322-1.152-1.133-1.63-1.753c-.298-.385-.732-.866-1.398-1.088a1 1 0 0 0-.632 1.898c.558.186.944 1.142 1.298 1.566c.373.448.869.916 1.58 1.218c.682.29 1.483.393 2.438.276V21a1 1 0 0 0 2 0v-3c0-.574.24-1.09.629-1.456c.607-.572.297-1.568-.495-1.726C6.969 14.187 5 12.114 5 10c0-.958.385-1.881 1.108-2.684c.283-.314.357-.756.207-1.14" />
                        </g>
                    </svg>
                </a>
                <div class="project-wrapper">
                    <img src="../assets/techhubsouth-mockup.png" alt="Tech Hub South">
                    <div class="project-title">TechHub South</div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
  name: "LatestProjects",
};
</script>

<style lang="css" scoped>
.latestprojects {
    background-color: #1D1D1D;
    color: white;
    height: fit-content;
    min-height: 80vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}

.latestprojects .row {
    margin: 2rem;
    width: auto;
}

.latestprojects .nadpis {
    display: flex;
}

.latestprojects h2 {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.projekty {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.latestproject {
    background-color: #B0B0B0;
    height: 65vh;
    width: 45%;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden; /* Ensure the hover effect stays within the div */
    position: relative; /* Context for absolute positioning */
    padding: 0rem!important;
}

.latestproject .project-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
}

.latestproject img {
    object-fit: cover;
    height: 80%; /* Reduced from 100% to make the image smaller */
    width: auto; /* Adjust width to maintain aspect ratio */
    transition: transform 0.3s ease-in-out; /* Smooth zoom */
    margin: auto; /* Center the image */
}

.latestproject .project-title {
    position: absolute;
    bottom: -60px; /* Start below the div */
    left: 0;
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    text-align: center;
    color: #fff;
    padding: 10px 0;
    transition: bottom 0.3s ease-in-out; /* Animate the title appearance */
    font-size: 1.2rem;
    font-weight: 700;
}

.latestproject:hover .project-wrapper {
    transform: scale(1.05); /* Zoom effect on hover */
}

.latestproject:hover .project-title {
    bottom: 0; /* Slide up the title on hover */
}


.latestproject .live-link {
    position: absolute;
    top: 20px;  /* Adjust spacing from the top */
    right: 20px;  /* Adjust spacing from the right */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;  /* Make it circular */
    width: 50px;  /* Circle size */
    height: 50px;  /* Circle size */
    cursor: pointer;  /* Pointer cursor on hover */
    z-index: 2;
    text-decoration: none;  /* Remove underline */
    color:#fff;
}

.latestproject .live-link:hover {
    background-color: #d55500;
    color: #ffb700;  /* Change background color on hover */
}

.latestproject .live-link svg {
    fill: #fff;  /* SVG icon color */
    width: 30px;  /* Icon size */
    height: 30px;  /* Icon size */
}

.latestproject .github-link {
    position: absolute;
    top: 75px;  /* Position it below the live link */
    right: 20px;  /* Align with the live link */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    z-index: 2;
    text-decoration: none;
    color: #fff;
}

.latestproject .github-link:hover {
    background-color: #6e5494;  /* GitHub brand color for hover */
    color: #ffb700
}

.latestproject .github-link svg {
    fill: #fff;
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 990px){
    .projekty{
        flex-direction: column;
    }

    .latestproject {
        height: 30vh;
        width: 90%;
    }

    .latestprojects .row{
        margin: 0rem!important;
    }

    .nadpis h2{
        margin-bottom: 2rem;
        margin-top: 2.5rem;
    }

    .latestproject img{
        height: 75%;
    }

    .latestproject .github-link {
    top: 25px;  /* Position it below the live link */
    left: 20px;  /* Align with the live link */
}

}

</style>

<template>
  <div class="education container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-3 flex-col educ">
        <h2 class="text-center">Education</h2>
      </div>

      <div class="col-sm-12 col-md-9 flex-col education-details">
        <div class="edu">
          <p class="firstrow">
            <span class="degree">Web Development, <span class="degreelight">Bachelor's degree</span></span> 
            <span class="years">2023 - PRESENT</span>
          </p>
          <p class="school">
            Business Academy Southwest - Esbjerg, Denmark
          </p>
          <ul>
            <li>
              <p>
                TypeScript, JavaScript, Vue.js, PHP, MongoDB, MySQL, Figma, Git, UX/UI, APIs
              </p>
            </li>
          </ul>
        </div>

        <div class="edu">
          <p class="firstrow">
            <span class="degree">Multimedia Design and Communication, <span class="degreelight">AP degree</span></span> 
            <span class="years">2021 - 2023</span>
          </p>
          <p class="school">
            Business Academy Southwest - Esbjerg, Denmark
          </p>
          <ul>
            <li>
              <p>
                Teamwork, Web Design, UX/UI, HTML, CSS, SCSS, Bootstrap, SCRUM, WordPress, Git
              </p>
            </li>
          </ul>
        </div>

        <div class="edu">
          <p class="firstrow">
            <span class="degree">Information and Network Technologies, <span class="degreelight">High School</span></span> 
            <span class="years">2016 - 2020</span>
          </p>
          <p class="school">
            Technical Academy - Spišská Nová Ves, Slovakia
          </p>
          <ul>
            <li>
              <p>
                Python, C#, HTML, CSS, JavaScript
              </p>
            </li>
          </ul>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationComponent",
};
</script>

<style lang="css" scoped>
.education {
  background-color: #1d1d1d;
  color: white;
  height: fit-content;
  min-height: 80vh;
  max-width: 100vw;
  display: flex;
/*   justify-content: center;
  align-items: center; */
}

.education .row {
  margin: 2rem;
  width: 100%;
}

.education .educ{
    display: flex;
}

.education h2 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.education .education-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.education .education-details .edu {
  margin-left: 2rem;
  margin-right: 6rem;
}

.education .education-details .edu p {
  margin-bottom: 0rem;
}

.education .education-details .edu .firstrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
}

.education .education-details .edu p .degreelight {
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.education .education-details .edu p .degree {
  font-size: 1.7rem;
  font-weight: bold;
  color: #ffb700;
}

.education .education-details .edu p .years {
  font-size: 0.9rem;
  font-weight: 300;
}

.education .education-details .edu p.school {
  font-size: 1.1rem;
  font-weight: 300;
  font-style: italic;
}

.education .education-details .edu ul li p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  margin-right: 1rem;
}

@media only screen and (max-width: 990px){
    .education {
        justify-content: center;
        align-items: center;
    }

    .education .row{
        margin: 0rem!important;
    }

    .education .educ {
        display: flex;
    }

    .education .education-details{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .education .education-details .edu{
        margin-left: 0rem;
        margin-right: 0rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    .education .education-details .edu p{
        margin-bottom: 0rem;
    }

    .education .education-details .edu .firstrow{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        line-height: 1.5;
        gap: 0.6rem;
    }

    .education .education-details .edu p .degreelight{
        font-size: 1rem;
    }

    .education .education-details .edu p .degree{
        font-size: 1.3rem;
    }

    .education .education-details .edu p .years{
        font-size: 0.7rem;
    }

    .education .education-details .edu p.school{
        font-size: 0.9rem;
        font-weight: 300;
    }

    .education .education-details .edu ul li p{
        margin-right: 0rem;
    }

    .education h2{
        margin-bottom: 1.5rem;
        margin-top: 2rem;
    }
}

</style>

<template>
  <div class="intro-container container-fluid">
    <div class="row align-items-center justify-content-center">
      <div class="col-sm-12 col-md-5 flex-col profimg">
        <img
          src="@/assets/zuzana.png"
          alt="Zuzana Gabonayova"
          class="img-fluid profile-image"
        />
      </div>
      <div class="col-sm-12 col-md-7 flex-col proftext">
        <div class="text-container">
          <p class="hello laptop">
            Hello, 
            <br>I am Zuzana Gabonayová, a <span class="highlight">Web Developer</span>.
          </p>
          <p class="laptop">
            I'm a passionate and creative Web Developer specializing in
            <br><span class="highlight">front-end</span> technologies. I'm currently pursuing a Web 
            <br>Development Bachelor's degree at Business Academy 
            <br>SouthWest in Denmark, where I dive deep into the world of 
            <br>coding and designing fully functional websites and apps.
          </p>
          <p class="laptop">
            At the moment, I am honing my skills with <span class="highlight">REST APIs</span>,
            <span class="highlight">Tailwind <br>CSS</span>, and
            <span class="highlight">Unit Testing</span>. I am always expanding my toolbox and 
            <br>knowledge, currently diving deep into the <span class="highlight">VueJS framework</span> 
            <br>and <span class="highlight">JavaScript</span>. I am passionate about building responsive, 
            <br>SEO-optimized, and user-friendly websites.
          </p>

          <p class="hello mobile">
            Hello, 
            <br>I am Zuzana Gabonayová, a <span class="highlight">Web Developer</span>.
          </p>
          <p class="mobile">
            I'm a passionate and creative Web Developer specializing in
            <span class="highlight">front-end</span> technologies. I'm currently pursuing a Web 
            Development Bachelor's degree at Business Academy 
            SouthWest in Denmark, where I dive deep into the world of 
            coding and designing fully functional websites and apps.
          </p>
          <p class="mobile">
            At the moment, I am honing my skills with <span class="highlight">REST APIs</span>,
            <span class="highlight">Tailwind CSS</span>, and
            <span class="highlight">Unit Testing</span>. I am always expanding my toolbox and 
            knowledge, currently diving deep into the <span class="highlight">VueJS framework</span> 
            and <span class="highlight">JavaScript</span>. I am passionate about building responsive, 
            SEO-optimized, and user-friendly websites.
          </p>

          <div class="buttons-container">
            <a href="mailto:gabonayova.zuzka@gmail.com" class="contactme">
              <button class="contact">
                Contact me
              </button>
            </a>

            <a href="./CVZuzanaGabonayova.pdf" class="readcv" target="_blank">
              <button class="cv" >
                Read CV
              </button>
            </a>
          </div>

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 ddd">
        <h1>Design. Develop. Deploy.</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroductionComponent",
};
</script>

<style lang="css" scoped>
.intro-container{
  background-color: #1D1D1D;
  color: white;
  height: fit-content;
  padding-top: 2rem;
  padding-bottom: 1rem;
  min-height: 100vh;
  max-width: 100vw;
  margin-top: 2rem;
}

.intro-container .profimg{
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-container .profimg img{
  max-height: 80vh;
  width: auto;
}

.intro-container .proftext{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  letter-spacing: 0.05rem;
}

/* .intro-container .text-container{
  padding: 2rem;
} */

.intro-container .text-container p{
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 400;
}

.intro-container .text-container p.mobile{
  display: none;
}

.intro-container .text-container p .highlight{
  font-weight: 600;
  color: #ffb700;
}

.intro-container .proftext .buttons-container{
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}

.intro-container .proftext .buttons-container button{
  padding: 0.3rem;
  height: 50px;
  width: 120px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.intro-container .proftext .buttons-container button.contact{
  background-color: #9a9a9a;;
}

.intro-container .proftext .buttons-container button.contact:hover{
  background-color: #4d4d4d;
}

.intro-container .proftext .buttons-container button.cv{
  background-color: #4d4d4d;;
}

.intro-container .proftext .buttons-container button.cv:hover{
  background-color: #9a9a9a;
}


.intro-container .ddd{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.intro-container .ddd h1{
  font-size: 5.5rem;
  font-weight: 700;
  letter-spacing: 0.35rem;
  background: -webkit-linear-gradient(45deg, #AE0F0F, #ffb700);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



@media only screen and (max-width: 990px){
  .intro-container{
    margin-top: 4rem;
  }

  .intro-container .profimg{
    order: 2;
    margin-top: 1rem;
  }

  .intro-container .profimg img{
    max-height: 60vh;
  }

  .intro-container .text-container p.mobile{
    display: block;
  }

  .intro-container .text-container p.laptop{
    display: none;
  }

  .intro-container .text-container p{
    font-size: 1rem;
  }

  .intro-container .proftext{
    order: 1;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .intro-container .proftext .buttons-container{
    justify-content: center;
  }

  .intro-container .ddd{
    padding: 2.5rem;
  }

  .intro-container .ddd h1{
    font-size: 4rem;
    background: -webkit-linear-gradient(0deg, #AE0F0F, #ffb700);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

/*
@media (max-width: 768px) {
  .profile-image {
    max-width: 50%;
  }
} */
</style>
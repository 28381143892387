<template>
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="container-fluid">
      <!-- Logo -->
      <a class="navbar-brand" href="#">
        <div><img src="../assets/logo.png" alt="logo" id="logo" /></div>
      </a>
      <!-- Hamburger menu toggle button for mobile -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon">
          <i class="fas fa-bars" style="color: #fff; font-size: 28px"></i>
        </span>
      </button>
      <!-- Navbar links -->
      <div id="navbarNavAltMarkup" class="collapse navbar-collapse">
        <ul id="navbar-nav" class="navbar-nav ml-auto">
          <li>
            <a class="nav-item nav-link" href="#projects">Projects</a>
          </li>

          <li>
            <a class="nav-item nav-link" href="mailto:gabonayova.zuzka@gmail.com">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                >
                <g
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                >
                    <path
                    d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                    />
                    <path d="m3 7l9 6l9-6" />
                </g>
                </svg>
            </a>
          </li>

          <li>
            <a class="nav-item nav-link" href="tel:+421950797735">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 256 256"
                >
                <path
                    fill="currentColor"
                    d="m222.37 158.46l-47.11-21.11l-.13-.06a16 16 0 0 0-15.17 1.4a8.12 8.12 0 0 0-.75.56L134.87 160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16 16 0 0 0 1.32-15.06v-.12L97.54 33.64a16 16 0 0 0-16.62-9.52A56.26 56.26 0 0 0 32 80c0 79.4 64.6 144 144 144a56.26 56.26 0 0 0 55.88-48.92a16 16 0 0 0-9.51-16.62M176 208A128.14 128.14 0 0 1 48 80a40.2 40.2 0 0 1 34.87-40a.61.61 0 0 0 0 .12l21 47l-20.67 24.74a6.13 6.13 0 0 0-.57.77a16 16 0 0 0-1 15.7c9.06 18.53 27.73 37.06 46.46 46.11a16 16 0 0 0 15.75-1.14a8.44 8.44 0 0 0 .74-.56L168.89 152l47 21.05h.11A40.21 40.21 0 0 1 176 208"
                />
                </svg>
            </a>
          </li>

          <!-- <li>
            <a class="nav-item nav-link changethemecolor" href="#">
                <div class="withlightbackground">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 256 256"
                    >
                    <path
                        fill="currentColor"
                        d="M116 36V20a12 12 0 0 1 24 0v16a12 12 0 0 1-24 0m80 92a68 68 0 1 1-68-68a68.07 68.07 0 0 1 68 68m-24 0a44 44 0 1 0-44 44a44.05 44.05 0 0 0 44-44M51.51 68.49a12 12 0 1 0 17-17l-12-12a12 12 0 0 0-17 17Zm0 119l-12 12a12 12 0 0 0 17 17l12-12a12 12 0 1 0-17-17M196 72a12 12 0 0 0 8.49-3.51l12-12a12 12 0 0 0-17-17l-12 12A12 12 0 0 0 196 72m8.49 115.51a12 12 0 0 0-17 17l12 12a12 12 0 0 0 17-17ZM48 128a12 12 0 0 0-12-12H20a12 12 0 0 0 0 24h16a12 12 0 0 0 12-12m80 80a12 12 0 0 0-12 12v16a12 12 0 0 0 24 0v-16a12 12 0 0 0-12-12m108-92h-16a12 12 0 0 0 0 24h16a12 12 0 0 0 0-24"
                    />
                    </svg>
                </div>
            </a>
          </li> -->
          
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavigationBar",
};
</script>

<style lang="css" scoped>

nav {
  background-color: #1d1d1d;
  height: 100px;
}

nav .navbar-brand {
  margin-left: 3rem;
  justify-content: flex-start;
}

nav .navbar-brand #logo {
  max-height: 4rem;
}

nav #navbarNavAltMarkup {
  margin-right: 3rem;
  justify-content: flex-end;
}

nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li a {
  color: #fff;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-decoration: none;
}

nav ul li a:hover {
  color: #ffb700;
}

nav ul li a svg {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

nav ul li a .withlightbackground {
  background-color: #9a9a9a;
  border-radius: 15px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li a .withlightbackground:hover {
  background-color: #4d4d4d;
}

@media only screen and (max-width: 990px){
  nav .navbar-brand {
    margin-left: 1rem;
  }
  nav #navbarNavAltMarkup {
    margin-right: 1rem;
  }

  .navbar-toggler{
    margin-right: 1rem;
    border: 1px solid white;
  }

  nav{
    height: auto;
  }

  #navbarNavAltMarkup{
    margin: 0px!important;
  }

}

/* $bg-color: #1d1d1d;
bg-color-hover: #4d4d4d;
$text-color: #fff;
$lightbackground-color: #9a9a9a;
$yellow: #ffb700;
*/
</style>

<template>
  <div class="experience container-fluid">
    <div class="row">

        <div class="col-sm-12 col-md-3 flex-col exp">
            <h2 class="text-center">Experience</h2>
        </div>

        <div class="col-sm-12 col-md-9 flex-col experience-details">
            <div class="job">
                <p class="firstrow"><span class="position">Web Administrator <span class="explight">- Freelance</span></span>
                <span class="years">March 2023 - PRESENT</span></p>
                <p class="place">Nostalgie Restaurant - Café, Spišská Nová Ves, Slovakia</p>
                <ul>
                    <li><p>developed responsive website utilizing PHP, Bootstrap,  CSS, JavaScript using CMS WordPress and its features, fully coded custom theme using LazyBlocks plugin</p></li>
                    <li><p>followed the most effective SEO practices</p></li>
                    <li><p>conducted usability testing and implemented further improvements to optimize UX</p></li>
                    <li><p>actively ensure smooth running of current restaurant's website, updating content, creating backups, fixing unexpected website/server issues</p></li>
                </ul>
            </div>

            <div class="job">
                <p class="firstrow"><span class="position">ServiceNow Developer <span class="explight">- Internship</span></span>
                <span class="years">January 2023 - Jun 2023</span></p>
                <p class="place">Deutsche Telekom IT Solutions Slovakia, Košice, Slovakia</p>
                <ul>
                    <li><p>part of Global Process Management team, expanded knowledge about ITIL, ITSM, and Agile</p></li>
                    <li><p>development and improvement of scripts running on internal ServiceNow platform</p></li>
                    <li><p>creating Update Sets while development, to ensure all changes can be exported for further use in all other running instances, defining Business Rules, and Code Development, followed by performing Unit Tests</p></li>
                </ul>
            </div>
            
        </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ExperienceComponent",
};
</script>

<style lang="css" scoped>

.experience {
  background-color: #1D1D1D;
  color: white;
  height: fit-content;
  min-height: 80vh;
  max-width: 100vw;
  display: flex;
/*   justify-content: center;
  align-items: center; */
}

.experience .row{
    margin: 2rem;
    width: 100%;
}

.experience .exp {
    display: flex;
}

.experience h2{
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.experience .experience-details{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.experience .experience-details .job{
    margin-left: 2rem;
    margin-right: 6rem;
}

.experience .experience-details .job p{
    margin-bottom: 0rem;
}

.experience .experience-details .job .firstrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
}

.experience .experience-details .job p .explight{
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
}

.experience .experience-details .job p .position{
    font-size: 1.7rem;
    font-weight: bold;
    color: #ffb700;
}

.experience .experience-details .job p .years{
    font-size: 0.9rem;
    font-weight: 300;
}

.experience .experience-details .job p.place{
    font-size: 1.1rem;
    font-weight: 300;
    font-style: italic;
}

.experience .experience-details .job ul li p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    margin-right: 1rem;
}


@media only screen and (max-width: 990px){
    .experience {
        justify-content: center;
        align-items: center;
    }

    .experience .row{
        margin: 0rem!important;
    }

    .experience .exp {
        display: flex;
    }

    .experience .experience-details{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .experience .experience-details .job{
        margin-left: 0rem;
        margin-right: 0rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }

    .experience .experience-details .job p{
        margin-bottom: 0rem;
    }

    .experience .experience-details .job .firstrow{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        line-height: 1.5;
        gap: 0.6rem;
    }

    .experience .experience-details .job p .explight{
        font-size: 1rem;
    }

    .experience .experience-details .job p .position{
        font-size: 1.3rem;
    }

    .experience .experience-details .job p .years{
        font-size: 0.7rem;
    }

    .experience .experience-details .job p.place{
        font-size: 0.9rem;
        font-weight: 300;
    }

    .experience .experience-details .job ul li p{
        margin-right: 0rem;
    }

    .experience h2{
        margin-bottom: 1.5rem;
    }
}


</style>
